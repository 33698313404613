<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<style scoped>
  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  .light-table tbody tr.active {
    color: #505d69;
    background-color: #c2cad3;
  }
</style>
<script>
  import $ from "jquery"
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    getZkzk,
    // addCjk,
    editZkz,
    deleteZkz,
    // getZkzkInfo
  } from "@/api/printCard/printCard.js"
  import {
    getStore,
    setStore,
  } from '@/libs/storage';

  /**
   * 准考证库列表
   */
  export default {
    components: {
      Layout,
      PageHeader,



    },
    data() {
      return {
        title: "准考证库列表 ",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "准考证库",
            active: true
          }
        ],
        tableList: [],
        years: [],
        exportTitle: "准考证库导出",
        module: "ZKZKJL",
        newAciveIndex: "",
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          ksnf: new Date().getFullYear(),
          keyWord: "",
          keyParam: "ksmc"
        },
      };
    },
    computed: {
      cjfhfw() {
        return function(a) {
          let box = this.fhfw;
          let str = ""
          for (let i = 0; i < a.length; i++) {
            if (a[i] == "1") {
              str = str + box[i] + ","
            }
          }
          if (str) {
            str = str.slice(0, -1)
          }
          return str;
        }
      }
    },
    methods: {
      getList() {
        getZkzk(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total


          }
        })
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      //获取当前年份开始的前后几年
      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      // 删除
      deleteItem(obj) {
        this.$confirm(`此操作将永久删除该【${obj.ksmc}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteZkz(obj.sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 编辑准考证库
      editZkz(sid, sfyxdy) {
        let formData = {}
        formData.sid = sid
        formData.sfyxdy = sfyxdy ? 1 : 0
        editZkz(formData).then(res => {
          if (res.status) {
            this.$message({
              type: "success",
              message: res.message,
            });
          }
        })
      },
      gotoPage(sid, index, page) {
        window.location.href = "/admin/" + page + "?sid=" + sid
        this.activeTr(index)

      },

      activeTr(index) {
        $(".active").removeClass("active")
        $("#rowId_" + index).addClass("active")
      }
    },
    mounted() {
      this.getList()
      this.getYear()
    },


  }
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 " style="width:10%;" clearable placeholder="请选择考试年份" v-model="pageData.ksnf"
                  size="small">
                  <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 form-control border-blue mr-2 w-15" maxlength="50"
                  v-model="pageData.keyWord" />
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <a href="/admin/printCardImport">
                  <b-button v-b-modal.more-condition variant="outline-info" class="flexList mr-2 condition"><i
                      class="iconfont icon-riLine-upload-2-line mr-2"></i>准考证库导入</b-button>
                </a>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th>考试名称 </th>
                    <th>打印开放日期</th>
                    <th style="width: 12%;">打印结束日期 </th>
                    <th style="width: 6%;">操作人</th>
                    <th style="width: 12%;">操作时间</th>
                    <th style="width: 10%;">是否允许打印准考证</th>
                    <th style="width: 5%;" class="text-center">操作 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index" :id="'rowId_'+index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>
                      <div class="font-blue" @click="gotoPage(obj.sid,index,'printCardManage')">{{obj.ksmc}}</div>
                    </td>
                    <td> {{obj.kfrq}} </td>
                    <td>
                      <div>{{obj.jsrq}}</div>
                    </td>
                    <td> {{obj.modifyUser}} </td>
                    <td> {{obj.modifyTime}} </td>
                    <td class="text-center">
                      <b-form-checkbox v-model="obj.sfyxdy" switch class="mt-1 switch-check" :value="1"
                        :unchecked-value="0" @change="editZkz(obj.sid,obj.sfyxdy)">
                      </b-form-checkbox>

                    </td>

                    <td class="tab-icon">
                      <i class="iconfont icon-edit-two " @click="gotoPage(obj.sid,index,'printCardImportMatch' )"></i>
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj)"></i>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
